import React, { useState, useEffect } from 'react';
import { updateInstallmentStatus, getInstallments } from '../services/clientService';
import './InstallmentCalculator.css';

function InstallmentCalculator({ clientId, contractAmount, prepaymentAmount, installmentTerm }) {
  const [installments, setInstallments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetchInstallments();
  }, [clientId]);

  useEffect(() => {
    calculateInstallments();
  }, [contractAmount, prepaymentAmount, installmentTerm]);

  const fetchInstallments = async () => {
    try {
      const response = await getInstallments(clientId);
      const installmentsWithId = response.data.map((installment) => ({
        ...installment,
        id: installment.id // Убедитесь, что ID присутствует
      }));
      setInstallments(installmentsWithId);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching installments:', error);
      setIsLoading(false);
    }
  };

  const calculateInstallments = () => {
    if (!contractAmount || !prepaymentAmount || !installmentTerm) {
      setInstallments([]);
      return;
    }

    const remainingAmount = contractAmount - prepaymentAmount;
    const monthlyPayment = remainingAmount / installmentTerm;
    const newInstallments = [];

    for (let i = 1; i <= installmentTerm; i++) {
      newInstallments.push({
        dueDate: new Date(Date.now() + i * 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        amount: monthlyPayment,
        isPaid: false,
        comment: '',
      });
    }

    setInstallments(newInstallments);
  };

  const handlePaymentToggle = async (index) => {
    const installment = installments[index];
    const updatedIsPaid = !installment.isPaid; // Новое значение isPaid

    try {
      await updateInstallmentStatus(clientId, installment.id, updatedIsPaid); // Обновлено для использования нового метода
      fetchInstallments(); // Перезагрузите данные рассрочек после обновления
    } catch (error) {
      console.error('Ошибка при обновлении статуса рассрочки:', error);
    }
  };

  const handleCommentChange = (index, comment) => {
    const updatedInstallments = [...installments];
    updatedInstallments[index].comment = comment;
    setInstallments(updatedInstallments);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!installments.length) {
    return <div>Нет данных о рассрочке</div>;
  }

  return (
    <div className="installment-calculator">
      <h3>График платежей</h3>
      <table>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Статус</th>
            <th>Комментарий</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          {installments.map((installment, index) => (
            <tr key={index}>
              <td>{installment.dueDate}</td>
              <td>{installment.amount.toFixed(2)}</td>
              <td>{installment.isPaid ? 'Оплачено' : 'Не оплачено'}</td>
              <td>
                <input
                  type="text"
                  value={installment.comment}
                  onChange={(e) => handleCommentChange(index, e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handlePaymentToggle(index)}>
                  {installment.isPaid ? 'Отменить оплату' : 'Отметить как оплаченный'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default InstallmentCalculator;
