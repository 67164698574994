// components/withAuth.js
import React, { useEffect } from 'react';
import { isAuthenticated } from '../services/authService';
import { useNavigate } from 'react-router-dom'; // Используем для редиректа

function withAuth(WrappedComponent) {
  return function ProtectedComponent(props) {
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated()) {
        // Если пользователь не аутентифицирован, редирект на логин
        navigate('/login');
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };
}

export default withAuth;
