import axios from 'axios';
import { getToken } from './authService';

const API_URL = 'https://skyneocrm.ru/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getUsers = () => {
  return axiosInstance.get('/users');
};

export const createUser = (userData) => {
  return axiosInstance.post('/users', userData);
};

export const deleteUser = (userId) => {
  return axiosInstance.delete(`/users/${userId}`);
};