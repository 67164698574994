import React, { useState, useEffect } from 'react';
import ClientTable from '../components/ClientTable';
import ClientDetails from '../components/ClientDetails';
import SearchBar from '../components/SearchBar';
import { getClients, searchClients, updateClient } from '../services/clientService';
import { getCurrentUser } from '../services/authService';

function ManagerPage() {
  const [clients, setClients] = useState([]); // Список клиентов
  const [selectedClient, setSelectedClient] = useState(null); // Выбранный клиент
  const [searchQuery, setSearchQuery] = useState(''); // Запрос для поиска
  const [user, setUser] = useState(null); // Информация о пользователе

  // Эффект для загрузки клиентов и текущего пользователя
  useEffect(() => {
    fetchClients(); // Получаем список клиентов
    setUser(getCurrentUser()); // Устанавливаем текущего пользователя
  }, []);

  // Функция для получения клиентов
  const fetchClients = async () => {
    try {
      const response = await getClients(); // Запрос на получение клиентов
      setClients(response.data); // Обновляем состояние клиентов
    } catch (error) {
      console.error('Ошибка при получении клиентов:', error); // Обработка ошибок
    }
  };

  // Обработчик клика по клиенту
  const handleClientClick = (client) => {
    setSelectedClient(client); // Устанавливаем выбранного клиента
  };

  // Обработчик поиска
  const handleSearch = async (query) => {
    setSearchQuery(query); // Устанавливаем запрос для поиска
    if (query) {
      try {
        const response = await searchClients(query); // Поиск клиентов по запросу
        setClients(response.data); // Обновляем список клиентов
      } catch (error) {
        console.error('Ошибка при поиске клиентов:', error); // Обработка ошибок
      }
    } else {
      fetchClients(); // Если нет запроса, возвращаем всех клиентов
    }
  };

  // Обработчик обновления клиента
  const handleUpdateClient = async (clientId, clientData) => {
    try {
      await updateClient(clientId, clientData); // Обновляем информацию о клиенте
      fetchClients(); // Обновляем список клиентов
    } catch (error) {
      console.error('Ошибка при обновлении клиента:', error); // Обработка ошибок
    }
  };

  // Проверка прав пользователя на редактирование клиента
  const canEditClient = (client) => {
    if (!user || !Array.isArray(user.permissions)) return false; // Если нет пользователя или прав
    return user.permissions.includes(client.workType); // Проверяем права на редактирование
  };

  return (
    <div className="manager-page">
      <h2>Панель управления менеджера</h2>
      <SearchBar onSearch={handleSearch} />
      <div className="content">
        <div className="client-table">
          <ClientTable
            clients={clients}
            onClientClick={handleClientClick}
          />
        </div>
        <div className="client-details">
          {selectedClient && (
            <ClientDetails
              client={selectedClient}
              onUpdateClient={handleUpdateClient}
              canEditClient={canEditClient}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ManagerPage;
