import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import AdminPage from './pages/AdminPage';
import MainManagerPage from './pages/MainManagerPage';
import ManagerPage from './pages/ManagerPage';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route 
              path="/admin" 
              element={
                <PrivateRoute>
                  <AdminPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/main-manager" 
              element={
                <PrivateRoute>
                  <MainManagerPage />
                </PrivateRoute>
              } 
            />
            <Route 
              path="/manager" 
              element={
                <PrivateRoute>
                  <ManagerPage />
                </PrivateRoute>
              } 
            />
            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
