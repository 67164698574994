// services/authService.js
import axios from 'axios';

const API_URL = 'https://skyneocrm.ru/api';

// Функция для входа
export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/auth/login`, { username, password });
  if (response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data.user));  // Сохраняем пользователя
    localStorage.setItem('token', response.data.token);  // Сохраняем токен
  }
  return response.data.user;
};

// Функция для выхода
export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
};

// Функция для получения текущего пользователя
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

// Функция для проверки, аутентифицирован ли пользователь
export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

// Функция для получения токена
export const getToken = () => {
  return localStorage.getItem('token');
};

// Новый метод для получения профиля пользователя
export const getUserProfile = async () => {
  const token = getToken();  // Получаем токен из localStorage
  if (!token) {
    return null;  // Если токена нет, возвращаем null
  }

  try {
    const response = await axios.get(`${API_URL}/auth/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,  // Отправляем токен в заголовке запроса
      },
    });
    return response.data.user;  // Возвращаем данные пользователя
  } catch (error) {
    console.error('Error fetching user profile:', error);
    logout();  // Если токен недействителен, выполняем выход
    return null;
  }
};
