import React, { useState, useEffect } from 'react';
import ClientTable from '../components/ClientTable';
import ClientDetails from '../components/ClientDetails';
import ClientForm from '../components/ClientForm'; 
import SearchBar from '../components/SearchBar';
import { getClients, createClient, searchClients } from '../services/clientService';
import { getCurrentUser } from '../services/authService';
import withAuth from '../hoc/withAuth'; // Importing HOC

function MainManagerPage() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false); 

  useEffect(() => {
    fetchClients();
    setUser(getCurrentUser()); 
  }, []);

  const fetchClients = async () => {
    try {
      const response = await getClients();
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientClick = (client) => {
    setSelectedClient(client); 
    setShowCreateForm(false); 
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query) {
      try {
        const response = await searchClients(query);
        setClients(response.data);
      } catch (error) {
        console.error('Error searching clients:', error);
      }
    } else {
      fetchClients();
    }
  };

  const handleCreateClient = async (clientData) => {
    try {
      await createClient(clientData); 
      setShowCreateForm(false);  
      fetchClients();  
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const canEditClient = (client) => {
    if (!user || !Array.isArray(user.permissions)) return false;
    return user.permissions.includes(client.workType);
  };

  return (
    <div className="main-manager-page">
      <h2>Main Manager Dashboard</h2>
      <SearchBar onSearch={handleSearch} />
      <div className="actions">
        <button onClick={() => setShowCreateForm(true)}>Создать клиента</button> 
      </div>
      <div className="content">
        <div className="client-table">
          <ClientTable
            clients={clients}
            onClientClick={handleClientClick}
          />
        </div>
        <div className="client-details">
          {selectedClient && (
            <ClientDetails
              client={selectedClient}
              onUpdateClient={handleCreateClient}
              canEditClient={canEditClient}
            />
          )}
          {showCreateForm && ( 
            <ClientForm onSubmit={handleCreateClient} />
          )}
        </div>
      </div>
    </div>
  );
}

export default withAuth(MainManagerPage); // Wrap page in HOC
