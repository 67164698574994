import React, { useState, useEffect } from 'react';
import { getUsers, createUser, deleteUser } from '../services/userService';

function AdminPage() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', role: '', permissions: {} });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
      await createUser(newUser);
      setNewUser({ username: '', password: '', role: '', permissions: {} });
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    setNewUser(prev => ({
      ...prev,
      permissions: { ...prev.permissions, [name]: checked }
    }));
  };

  return (
    <div className="admin-page">
      <h2>Admin Dashboard</h2>
      <form onSubmit={handleCreateUser}>
        <input
          type="text"
          name="username"
          value={newUser.username}
          onChange={handleInputChange}
          placeholder="Username"
          required
        />
        <input
          type="password"
          name="password"
          value={newUser.password}
          onChange={handleInputChange}
          placeholder="Password"
          required
        />
        <select
          name="role"
          value={newUser.role}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Role</option>
          <option value="manager">Manager</option>
          <option value="main_manager">Main Manager</option>
        </select>
        <div>
          <label>
            <input
              type="checkbox"
              name="windows"
              checked={newUser.permissions.windows || false}
              onChange={handlePermissionChange}
            />
            Windows
          </label>
          <label>
            <input
              type="checkbox"
              name="balconies"
              checked={newUser.permissions.balconies || false}
              onChange={handlePermissionChange}
            />
            Balconies
          </label>
          <label>
            <input
              type="checkbox"
              name="doors"
              checked={newUser.permissions.doors || false}
              onChange={handlePermissionChange}
            />
            Doors
          </label>
          <label>
            <input
              type="checkbox"
              name="repair"
              checked={newUser.permissions.repair || false}
              onChange={handlePermissionChange}
            />
            Repair
          </label>
          <label>
            <input
              type="checkbox"
              name="siding"
              checked={newUser.permissions.siding || false}
              onChange={handlePermissionChange}
            />
            Siding
          </label>
        </div>
        <button type="submit">Create User</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Permissions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.role}</td>
              <td>{Object.entries(user.permissions || {}).filter(([, value]) => value).map(([key]) => key).join(', ')}</td>
              <td>
                <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminPage;
