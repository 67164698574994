import axios from 'axios';
import { getToken } from './authService';

const API_URL = 'https://skyneocrm.ru/api';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getClients = () => {
  return axiosInstance.get('/clients');
};

export const createClient = (clientData) => {
  return axiosInstance.post('/clients', clientData);
};

export const updateClient = (clientId, clientData) => {
  return axiosInstance.put(`/clients/${clientId}`, clientData);
};

export const searchClients = (query) => {
  return axiosInstance.get(`/clients/search?query=${query}`);
};

export const addNote = (clientId, noteData) => {
  return axiosInstance.post(`/clients/${clientId}/notes`, noteData);
};

export const getNotes = (clientId) => {
  return axiosInstance.get(`/clients/${clientId}/notes`);
};

// Обновлено для изменения статуса рассрочки
export const updateInstallmentStatus = (clientId, installmentId, isPaid) => {
  return axiosInstance.put(`/clients/${clientId}/installments/${installmentId}`, { isPaid });
};

export const addInstallment = (clientId, installmentData) => {
  return axiosInstance.post(`/clients/${clientId}/installments`, installmentData);
};

export const getInstallments = (clientId) => {
  return axiosInstance.get(`/clients/${clientId}/installments`);
};

export const logChange = (clientId, changeData) => {
  return axiosInstance.post(`/clients/${clientId}/changes`, changeData);
};

export const getChanges = (clientId) => {
  return axiosInstance.get(`/clients/${clientId}/changes`);
};
