import React, { useState, useEffect } from 'react';
import InstallmentCalculator from './InstallmentCalculator';
import './ClientDetails.css';

function ClientDetails({ client, onUpdateClient, canEditClient }) {
  const [formData, setFormData] = useState(client);

  useEffect(() => {
    setFormData(client);
  }, [client]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      isProblem: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass updated data to the upper level
    onUpdateClient(formData);
  };

  return (
    <div className="client-details">
      <h3>{client.fullName}</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Телефон:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            disabled={!canEditClient(client)}
          />
        </div>
        <div>
          <label>Адрес:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            disabled={!canEditClient(client)}
          />
        </div>
        <div>
          <label>Вид работы:</label>
          <select
            name="workType"
            value={formData.workType}
            onChange={handleInputChange}
            disabled={!canEditClient(client)}
          >
            <option value="">Выберите вид работы</option>
            <option value="windows">Окна</option>
            <option value="balconies">Балконы</option>
            <option value="doors">Двери</option>
            <option value="repair">Ремонт</option>
            <option value="siding">Отделка</option>
          </select>
        </div>
        <div>
          <label>Сумма договора:</label>
          <input
            type="number"
            name="contractAmount"
            value={formData.contractAmount}
            onChange={handleInputChange}
            disabled={!canEditClient(client)}
          />
        </div>
        <div>
          <label>Проблемный клиент:</label>
          <div className="problem-client-buttons">
            <button
              type="button"
              className={formData.isProblem ? 'active' : ''}
              onClick={() => handleCheckboxChange(true)}
              disabled={!canEditClient(client)}
            >
              Да
            </button>
            <button
              type="button"
              className={!formData.isProblem ? 'active' : ''}
              onClick={() => handleCheckboxChange(false)}
              disabled={!canEditClient(client)}
            >
              Нет
            </button>
          </div>
        </div>
        {canEditClient(client) && (
          <button type="submit">Сохранить изменения</button>
        )}
      </form>
      <InstallmentCalculator
        clientId={client.id}
        contractAmount={formData.contractAmount}
        prepaymentAmount={formData.prepaymentAmount}
        installmentTerm={formData.installmentTerm}
      />
    </div>
  );
}

export default ClientDetails;
