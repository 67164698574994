import React from 'react';

function ClientTable({ clients, onClientClick }) {
  const calculateInstallment = (contractAmount, prepaymentAmount, installmentTerm) => {
    const remainingAmount = contractAmount - prepaymentAmount;
    return remainingAmount > 0 ? (remainingAmount / installmentTerm).toFixed(2) : 'Нет данных';
  };

  return (
    <table>
      <thead>
        <tr>
          <th>ФИО</th>
          <th>Телефон</th>
          <th>Адрес</th>
          <th>Вид работ</th>
          <th>Рассрочка</th>
          <th>Проблемный клиент</th>
        </tr>
      </thead>
      <tbody>
        {clients.map(client => (
          <tr 
            key={client.id} 
            onClick={() => onClientClick(client)}
            style={{ backgroundColor: client.isProblem ? 'red' : 'white' }} // Красим строку в красный для проблемных клиентов
          >
            <td>{client.fullName}</td>
            <td>{client.phone}</td> {/* Отображение телефона */}
            <td>{client.address}</td>
            <td>{client.workType}</td> {/* Отображение вида работ */}
            <td>
              {calculateInstallment(client.contractAmount, client.prepaymentAmount, client.installmentTerm)}
            </td>
            <td>{client.isProblem ? 'Да' : 'Нет'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ClientTable;
