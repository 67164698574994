import React, { useState } from 'react';

function ClientForm({ onSubmit, initialData = {} }) {
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    phone: '',
    passportScan: '',
    workType: '',
    installmentTerm: 1,
    paymentType: '',
    contractAmount: 0,
    prepaymentAmount: 0,
    clientSource: '',
    comment: '',
    isProblem: false,
    notes: [],
    ...initialData
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value
    }));
  };

  const handleAddNote = () => {
    setFormData(prevData => ({
      ...prevData,
      notes: [...prevData.notes, { text: '', image: '', date: new Date() }]
    }));
  };

  const handleNoteChange = (index, e) => {
    const { name, value, files } = e.target;
    const updatedNotes = [...formData.notes];
    if (name === 'image') {
      updatedNotes[index][name] = files[0];
    } else {
      updatedNotes[index][name] = value;
    }
    setFormData(prevData => ({ ...prevData, notes: updatedNotes }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'notes') {
        formData[key].forEach((note, index) => {
          formDataToSend.append(`note_text_${index}`, note.text);
          if (note.image) {
            formDataToSend.append(`note_image_${index}`, note.image);
          }
          formDataToSend.append(`note_date_${index}`, note.date);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }
    onSubmit(formDataToSend);
  };

  const handleIsProblemChange = (value) => {
    setFormData(prevData => ({
      ...prevData,
      isProblem: value
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <input name="fullName" value={formData.fullName} onChange={handleChange} placeholder="ФИО" required />
      <input name="address" value={formData.address} onChange={handleChange} placeholder="Адрес" required />
      <input name="phone" value={formData.phone} onChange={handleChange} placeholder="Номер телефона" required />
      <input type="file" name="passportScan" onChange={handleChange} accept="image/*" />

      <select name="workType" value={formData.workType} onChange={handleChange} required>
        <option value="">Выберите вид работы</option>
        <option value="windows">Окна</option>
        <option value="balconies">Балконы</option>
        <option value="doors">Двери</option>
        <option value="repair">Ремонт</option>
        <option value="siding">Сайдинг</option>
      </select>

      <input type="number" name="installmentTerm" value={formData.installmentTerm} onChange={handleChange} min="1" max="12" />
      <select name="paymentType" value={formData.paymentType} onChange={handleChange} required>
        <option value="">Выберите вид оплаты</option>
        <option value="cash">Наличные</option>
        <option value="card">Безналичные</option>
        <option value="transfer">Перевод</option>
        <option value="office">Офис</option>
      </select>

      <input type="number" name="contractAmount" value={formData.contractAmount} onChange={handleChange} placeholder="Сумма договора" required />
      <input type="number" name="prepaymentAmount" value={formData.prepaymentAmount} onChange={handleChange} placeholder="Сумма предоплаты" required />
      <input name="clientSource" value={formData.clientSource} onChange={handleChange} placeholder="Откуда клиент" />
      <textarea name="comment" value={formData.comment} onChange={handleChange} placeholder="Комментарий" />

      <div>
        <label>Проблемный клиент:</label>
        <button type="button" onClick={() => handleIsProblemChange(true)}>Да</button>
        <button type="button" onClick={() => handleIsProblemChange(false)}>Нет</button>
      </div>

      <div>
        <h4>Заметки</h4>
        {formData.notes.map((note, index) => (
          <div key={index}>
            <input
              name="text"
              value={note.text}
              onChange={(e) => handleNoteChange(index, e)}
              placeholder="Текст заметки"
            />
            <input type="file" name="image" onChange={(e) => handleNoteChange(index, e)} accept="image/*" />
            <p>Дата: {note.date.toLocaleString()}</p>
          </div>
        ))}
        <button type="button" onClick={handleAddNote}>Добавить заметку</button>
      </div>

      <button type="submit">Сохранить клиента</button>
    </form>
  );
}

export default ClientForm;
