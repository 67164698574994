// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { getCurrentUser, getUserProfile, isAuthenticated, logout } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      if (isAuthenticated()) {
        try {
          const userProfile = await getUserProfile(); // Получаем данные пользователя с сервера
          setUser(userProfile);
        } catch (error) {
          logout(); // Выйти, если токен недействителен
        }
      }
      setLoading(false); // Завершаем состояние загрузки
    };

    initAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Можно добавить индикатор загрузки
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
